import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { useStyles, CustomSwitch } from '../styles';
import DeleteIcon from 'assets/images/deletes.svg';
import edit from 'assets/images/editBlue.svg';
import { useOutsideClick } from 'hooks/useOutSideClick';

const EditCardNoReview = ({
  intl,
  product,
  messages,
  editCard,
  checkHandler,
  checked,
  handleAgree,
  open,
  setOpen,
  handleClick,
}) => {
  const classes = useStyles();
  const editCardRef = useRef(null);

  useOutsideClick(editCardRef, handleClick, editCard);

  const handleDeleteClick = () => {
    setOpen({
      title: intl.formatMessage(messages.deleteProductAlertTitle),
      description: intl.formatMessage(messages.deleteProductAlertDescription),
      method: 'delete',
    });
  };

  return (
    <Paper
      ref={editCardRef}
      className={editCard ? classes.menuShow : classes.menuHide}
    >
      <MenuList>
        <MenuItem
          onClick={checkHandler}
          className={classes.item}
        >
          <ListItemIcon>
            <CustomSwitch
              checked={checked}
              color="primary"
            />
          </ListItemIcon>
          <ListItemText>
            {intl.formatMessage(
              checked
                ? messages.deactivationMessage
                : messages.activationMessage,
            )}
          </ListItemText>
        </MenuItem>
        <MenuItem className={classes.item}>
          <NavLink
            className={classes.item}
            to={`/profile/my-products/edit/${product?.id}/`}
          >
            <ListItemIcon>
              <img
                src={edit}
                alt="Изменить"
              />
            </ListItemIcon>
            <ListItemText>
              {intl.formatMessage(messages.activatedMessage)}
            </ListItemText>
          </NavLink>
        </MenuItem>
        <MenuItem
          onClick={handleDeleteClick}
          className={classes.item}
        >
          <ListItemIcon>
            <img
              alt=""
              src={DeleteIcon}
            />
          </ListItemIcon>
          <ListItemText className={classes.remove}>
            {intl.formatMessage(messages.delationProduct)}
          </ListItemText>
        </MenuItem>
      </MenuList>

      <AlertDialog
        open={!!open}
        title={open?.title || ''}
        description={open?.description || ''}
        onClose={() => setOpen(null)}
        onAgree={() => handleAgree(open?.method)}
      />
    </Paper>
  );
};

export default React.memo(EditCardNoReview);
