import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  OutlinedInput,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import messages from 'messages/BusinessProfile';

const Index = (props) => {
  const classes = useStyles();
  const [catalog, setCatalog] = useState({
    name: '',
  });
  const { intl, open, saveChanges, close } = props;

  const inputHandler = (e) => {
    setCatalog({ ...catalog, [e.target.name]: e.target.value });
  };

  const save = () => {
    saveChanges(catalog);
    close();
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      disableEscapeKeyDown
      onClose={close}
    >
      <DialogTitle>
        {/* FIX_ME: Нужен перевод */}
        Новый каталог
        {/* {intl.formatMessage({ ...messages.addCatalogTitle })} */}
      </DialogTitle>
      <DialogActions className={classes.field}>
        <OutlinedInput
          type="text"
          placeholder="Введите название каталога"
          name="name"
          onChange={(e) => inputHandler(e)}
        />
      </DialogActions>
      <DialogActions className={classes.actions}>
        <Button
          size="large"
          variant="outlined"
          onClick={close}
        >
          {intl.formatMessage({ ...messages.cancelChanges })}
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => save()}
          className={`${catalog.name.length <= 3 ? classes.disabled : ''}`}
          disabled={catalog.name.length <= 3 ? true : false}
        >
          {intl.formatMessage({ ...messages.addContact })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Index.propTypes = {
  intl: PropTypes.object,
  open: PropTypes.bool,
  saveChanges: PropTypes.func,
  close: PropTypes.func,
};

export default Index;
