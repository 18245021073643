import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  List,
  ListItemIcon,
} from '@material-ui/core';
import link from 'assets/images/BusinessIcon/navigate/link.svg';
import ListItem from 'fsd/entities/Chats/components/Contacts/ListItem';
import { colorSocial } from 'pages/BusinessRegistration/Components/BusinessEditInfo/Utilities/ContactTypes';
import { useStyles } from './styles';
import { useAlert } from 'react-alert';
import { ListToShare } from 'components/Product/ListToShare';
import { profileSelectors } from 'states/Profile';
import { useSelector } from 'react-redux';

const ModalCopyLinks = ({ open, handleClose, productId }) => {
  const classes = useStyles();
  const alert = useAlert();
  const fullUrl = window.location.href;
  const { origin } = new URL(fullUrl);

  const handleCopyClick = async () => {
    try {
      {
        /* FIX_ME: Нужен перевод */
      }
      const btnUrl = `${origin}/products/${productId}`;
      await navigator.clipboard.writeText(btnUrl);
      alert.success('Текст скопирован');
    } catch (err) {
      console.log(err);
      alert.error('Не удалось скопировать текст');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      className={classes.root}
    >
      {/* FIX_ME: Нужен перевед */}
      <DialogTitle>Выберите где хотите поделиться объявлением</DialogTitle>
      <DialogActions>
        <ListToShare
          handleClose={handleClose}
          type="product"
          id={productId}
        />
        {/* FIX_ME: Нужен перевед */}
        <Button
          className={classes.link}
          onClick={handleCopyClick}
        >
          <img
            src={link}
            alt="link"
          />
          {/* FIX_ME: Нужен перевод */}
          Скопировать ссылку
        </Button>
      </DialogActions>
      <DialogActions>
        <Button
          className={classes.close}
          onClick={handleClose}
        >
          {/* FIX_ME: Нужен перевод */}
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCopyLinks;
