import React from 'react';

import more from 'assets/images/more.svg';
import locationIcon from 'assets/images/subtractGrey.svg';
import setting from 'assets/images/BusinessIcon/navigate/setting-card.svg';

import { MyCheckbox, MyRadio, useStyles } from '../styles';
import {
  Box,
  Button,
  CardActions,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

export const Location = ({
  isMobile,
  isMyProfile,
  onUser,
  region,
  intl,
  handleClick,
  selectHandler,
  product,
  select,
  isSelect,
}) => {
  const classes = useStyles();

  return (
    <CardActions className={classes.cardActions}>
      <div className={classes.locationBurger}>
        <div className={classes.location}>
          <img
            src={locationIcon}
            alt=""
          />
          <Typography component={'p'}>{region}</Typography>
          <Typography component={'p'}>
            {product?.location?.[`title_${intl?.locale}`]?.slice(0, 10) + '...'}
          </Typography>
        </div>
        <div className={classes.burger}>
          {onUser &&
            (isMyProfile && isSelect ? (
              <div className={classes.checkedBtn}>
                <FormControlLabel
                  value="check"
                  control={
                    <MyCheckbox
                      checked={select}
                      onChange={selectHandler}
                    />
                  }
                />
              </div>
            ) : (
              <div
                className="moreButton"
                onClick={handleClick}
              >
                <img
                  src={more}
                  alt="More options" // Descriptive alt text for accessibility
                />
              </div>
            ))}
        </div>
      </div>
      {/* FIX_ME: Нужен перевод */}
      <div className={classes.settingBtn}>
        {isMyProfile && isSelect ? (
          <div className={classes.select}>
            <FormControlLabel
              value="check"
              control={
                <MyCheckbox
                  checked={select}
                  onChange={selectHandler}
                />
              }
            />
          </div>
        ) : (
          <Button onClick={handleClick}>
            <img
              src={setting}
              alt="Настройки"
            />
            Настроить
          </Button>
        )}
      </div>
    </CardActions>
  );
};

const ParentLocation = (
  props,
  { isMyProfile, isSelect, withoutSwitch, onReview },
) => {
  const classes = useStyles();

  return (
    <>
      {isMyProfile && !isSelect && !withoutSwitch && onReview ? (
        <div className={classes.footer}>
          <Location {...props} />
        </div>
      ) : (
        <Box className={classes.locationInfo}>
          <Location {...props} />
        </Box>
      )}
    </>
  );
};

export default React.memo(ParentLocation);
