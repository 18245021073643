import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@material-ui/core';

// styles
import { useStyles } from './styles';

// icons
import setting from 'assets/images/BusinessIcon/navigate/setting-card.svg';
import more from 'assets/images/BusinessIcon/navigate/more-catalog.svg';
import remove from 'assets/images/deletes.svg';
import edit from 'assets/images/editBlue.svg';
import plus from 'assets/images/plus_actions.svg';
import ImageGridCatalog from '../ui/ImageGridCatalog';
import { useDispatch } from 'react-redux';
import { BusinessActions } from 'states/Business';
import { Skeleton } from '@material-ui/lab';
import CatalogSkeleton from '../CatalogSkeleton';

const CatalogCard = ({
  intl,
  catalog,
  setCatalogData,
  handleSelectCatalog,
  changeCatalogProducts,
  setProducts,
  deleteCatalogHandler,
  changeCatalogHandler,
  updateCatalog,
  product,
  loading,
  isMyProfile,
  onUser,
  isSale = false,
  isProductModal = false,
}) => {
  const classes = useStyles();
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const isExternalView = onUser && isMyProfile && !isProductModal;

  const handleIsOpen = () => setIsOpen(!isOpen);

  const choiceCatalog = async () => {
    await dispatch(
      BusinessActions.sendProductToCatalog(catalog?.business, catalog.id, [
        product.id,
      ]),
    );
    await updateCatalog();
  };

  const handleCatalog = (catalog) => {
    if (isProductModal) {
      choiceCatalog();
    } else {
      changeCatalogProducts(catalog);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    if (isProductModal === false) {
      setCatalogData(catalog);
      setProducts(
        catalog?.products ? catalog?.products.map((product) => product.id) : [],
      );
    }
    return;
  }, []);

  if (loading) {
    return <CatalogSkeleton />;
  }

  return (
    <Card
      className={classes.root}
      onClick={() => handleCatalog(catalog)}
    >
      <CardActionArea>
        <ImageGridCatalog images={catalog?.products} />
        <CardActions className={classes.actions}>
          <CardContent className={classes.content}>
            <Typography
              className={`${isSale ? classes.sale : ''}`}
              variant="h5"
            >
              {catalog?.name}
            </Typography>
            <Box className={classes.wrapper}>
              <Typography
                className={`${isSale ? classes.sale : ''}`}
                variant="h5"
              >
                ({catalog?.products.length})
              </Typography>
              {!isSale && isExternalView && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIsOpen();
                  }}
                >
                  <img
                    src={more}
                    alt="More"
                  />
                </IconButton>
              )}
            </Box>
          </CardContent>
          {!isSale && isExternalView && (
            <Button
              className={classes.more}
              onClick={(e) => {
                e.stopPropagation();
                handleIsOpen();
              }}
            >
              {/* FIX_ME: Нужен перевод */}
              {isOpen ? (
                'Закрыть'
              ) : (
                <>
                  <img
                    src={setting}
                    alt="Настроить"
                  />
                  Настроить
                </>
              )}
            </Button>
          )}
        </CardActions>
      </CardActionArea>
      {isExternalView && (
        <CardActions
          ref={menuRef}
          className={isOpen ? classes.options : classes.optionsHide}
        >
          <MenuList>
            <MenuItem
              className={classes.optionItem}
              onClick={() => changeCatalogHandler(catalog)}
            >
              <ListItemIcon>
                <img
                  src={edit}
                  alt="Переименовать"
                />
              </ListItemIcon>
              <ListItemText>Переименовать</ListItemText>
            </MenuItem>
            <MenuItem
              className={classes.optionItem}
              onClick={() => handleSelectCatalog(catalog)}
            >
              <ListItemIcon>
                <img
                  src={plus}
                  alt="Редактировать каталог"
                />
              </ListItemIcon>
              <ListItemText>Редактировать каталог</ListItemText>
            </MenuItem>
            <MenuItem
              className={classes.optionItem}
              onClick={() => deleteCatalogHandler(catalog?.id)}
            >
              <ListItemIcon>
                <img
                  src={remove}
                  alt="Удалить каталог"
                />
              </ListItemIcon>
              <ListItemText className={classes.remove}>
                Удалить каталог
              </ListItemText>
            </MenuItem>
          </MenuList>
        </CardActions>
      )}
    </Card>
  );
};

export default CatalogCard;
