import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: 16,
    padding: '32px 0 0 0',
    '@media (max-width: 960px)': {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      justifyItems: 'center',
      gap: 16,
    },
    '@media (max-width: 618px)': {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: 16,
    },
    '@media (max-width: 320px)': {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      gap: 16,
      padding: '24px 0 0 0',
    },
  },
  rootProduct: {
    position: 'relative',
    background: '#fff',
    boxShadow: '0px 4px 15px 0px rgba(36, 86, 151, 0.10)',
    maxWidth: '255px',
    maxHeight: '380px',
    width: '100%',
  },
}));
