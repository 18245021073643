import { Box, Grid, makeStyles, styled } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  rootGrid: {
    height: 255,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 0,
    margin: 0,
    borderRadius: '5px 5px 0 0',
    overflow: 'hidden',
    '& .MuiGrid-item': {
      height: 128,
      width: '100%',
      padding: 0,
      outline: '1px solid #B1B1B1',
      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      },
      '@media (max-width: 560px)': {
        height: 64,
        width: '100%',
      },
    },
    '@media (max-width: 560px)': {
      width: '100%',
      height: 129,
    },
  },
}));

const Placeholder = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 128,
  backgroundColor: '#EAEAEA',
  '@media (max-width: 520px)': {
    height: 64,
    width: '100%',
  },
}));

const ImageGridCatalog = ({ images }) => {
  const classes = useStyles();

  const renderImagesWithPlaceholders = () => {
    const displayImages = images.slice(0, 4);
    while (displayImages.length < 4) {
      displayImages.push(null);
    }
    return displayImages;
  };

  return (
    <Grid
      className={classes.rootGrid}
      container
      spacing={2}
    >
      {renderImagesWithPlaceholders().map((image, index) => (
        <Grid
          item
          key={index}
        >
          {image ? (
            <Box
              component="img"
              src={image?.photos?.[0]?.medium_thumbnail}
            />
          ) : (
            <Placeholder />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageGridCatalog;
