import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiCardActions-spacing > :not(:first-child)': {
      marginLeft: '0px',
    },
    '.MuiCardContent-root:last-child': {
      padding: 0,
    },
  },
  root: {
    position: 'relative',
    width: '100%',
    minWidth: 139,
    height: 311,
    display: 'flex',
    borderRadius: '5px',
    background: theme.palette.primary.light,
    overflow: 'visible',
    boxShadow: '0px 4px 15px 0px rgba(36, 86, 151, 0.10)',
    '& .MuiCardActionArea-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    '@media (max-width: 560px)': {
      width: '100%',
      minWidth: 139,
      height: 199,
    },
  },
  images: {
    width: '100%',
    height: 255,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#D5E1F1',
    '& .MuiCardMedia-media': {
      height: 50,
      width: '100%',
      '@media (max-width: 560px)': {
        height: 24,
        width: '100%',
      },
    },
    '@media (max-width: 560px)': {
      height: 'auto',
      minHeight: 128,
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 8px',
    '@media (max-width: 560px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 8,
      padding: 8,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: 0,
    '& .MuiTypography-h5': {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.primary.dark,
      fontSize: 16,
      fontWeight: 400,
      '@media (max-width: 560px)': {
        fontSize: 13,
      },
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& .MuiTypography-h5': {
      color: theme.palette.primary.blue,
      fontSize: 16,
      fontWeight: 400,
      '@media (max-width: 560px)': {
        fontSize: 13,
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
      display: 'block',
      '@media (max-width: 560px)': {
        display: 'none',
      },
    },
  },
  more: {
    display: 'none',
    '@media (max-width: 560px)': {
      display: 'block',
      width: '100%',
      padding: '4px 14px',
      borderRadius: '4px',
      border: '1px solid #0051BA',
      '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
      },
    },
  },
  options: {
    display: 'block',
    position: 'absolute',
    top: '99%',
    left: 0,
    right: 0,
    zIndex: 99,
    background: theme.palette.primary.light,
    borderRadius: '0 0 5px 5px',
    boxShadow: '0px 15px 15px 0px rgba(36, 86, 151, 0.10)',
    '@media (max-width: 560px)': {
      padding: 8,
      '& .MuiList-root': {
        padding: 0,
      },
    },
  },
  optionsHide: {
    display: 'none',
  },
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '15px',
    padding: '6px 0',
    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      height: 20,
      width: 20,
    },
    '& .MuiListItemText-root': {
      '& span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
      },
    },
    '@media (max-width: 560px)': {
      minHeight: 'auto',
      gap: 4,
    },
  },
  remove: {
    color: '#FF4D4D',
  },
  sale: {
    color: '#FF4D4D !important',
  },
}));
