import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';

// messages
import messages from 'messages/BusinessProfile';

// icons
import plus from 'assets/images/BusinessIcon/navigate/catalog-add.svg';

// style
import { useStyles } from './styles';

const CatalogAdd = ({ intl, onClick }) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      onClick={onClick}
    >
      <CardActionArea>
        <CardContent className={classes.images}>
          <CardMedia
            component="img"
            image={plus}
            alt="Новый каталог"
          />
        </CardContent>
        <CardContent className={classes.content}>
          <Typography variant="h5">
            {/* {intl.formatMessage({
              ...messages.changeCatalog,
            })} */}
            {/* FIX_ME: Нужен перевод */}
            Новый каталог
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CatalogAdd;
