export const ACTION_TYPES = {
  SEND_REQUEST: 'SEND_REQUEST',
  SEND_LOGO: 'SEND_LOGO',
  GET_PROFILE: 'GET_PROFILE',
  SEND_CONTACTS: 'SEND_CONTACTS',
  SEND_SCHEDULES: 'SEND_SCHEDULES',
  SEND_CATALOG: 'SEND_CATALOG',
  SEND_DELIVERY: 'SEND_DELIVERY',
  GET_DELIVERY: 'GET_DELIVERY',
  CREATE_DELIVERY: 'CREATE_DELIVERY',
  SEND_BANNERS: 'SEND_BANNERS',
  DELETE_BANNER: 'DELETE_BANNER',
  SEND_PRODUCT_TO_CATALOG: 'SEND_PRODUCT_TO_CATALOG',
  REMOVE_PRODUCTS_FROM_CATALOG: 'REMOVE_PRODUCTS_FROM_CATALOG',
  GET_CATALOGS: 'GET_CATALOGS',
  GET_BUSINESS_PROFILE: 'GET_BUSINESS_PROFILE',
  UPDATE_BUSINESS_PROFILE: 'UPDATE_BUSINESS_PROFILE',
  GET_REVIEWS: 'GET_REVIEWS',
  SEND_REVIEWS: 'SEND_REVIEWS',
  GET_BUSINESS_REVIEWS: 'GET_BUSINESS_REVIEWS',
  SEND_ANSWER: 'SEND_ANSWER',
  SEND_CLAIM_TO_REVIEW: 'SEND_CLAIM_TO_REVIEW',
  SEND_STOCK_PRODUCTS: 'SEND_STOCK_PRODUCTS',
  FETCH_PRODUCT_STATS: 'FETCH_PRODUCT_STATS',
  FETCH_PRODUCT_STATS_BY_DATE: 'FETCH_PRODUCT_STATS_BY_DATE',
  FETCH_ALL_STATS: 'FETCH_ALL_STATS',
  UP_AND_PURCHASE_BALANCE: 'UP_AND_PURCHASE_BALANCE',
  PAYMENT_HISTORY: 'PAYMENT_HISTORY',
  FETCH_TARIFFS: 'FETCH_TARIFFS',
  PURCHASE_BUSINESS: 'PURCHASE_BUSINESS',
  CHANGE_CATALOG: 'CHANGE_CATALOG',
};

export const ENDPOINTS = {
  REQUEST: '/business/v1/requests/',
  PROFILE: '/business/v1/profile/',
  UPDATE_BUSINESS_PROFILE: '/business/v1/profile/',
  BUSINESS: '/business/v1/businesses/',
  CONTACTS: '/business/v1/profile/contacts/',
  SCHEDULES: '/business/v1/profile/schedule/',
  CATALOG: (ID) => `/business/v1/businesses/${ID}/catalog/`,
  CHANGE_CATALOG: (businessId, catalogId) =>
    `/business/v1/businesses/${businessId}/catalog/${catalogId}/`,
  BANNERS: '/business/v1/profile/banners/',
  DELETE_BANNER: (ID) => `/business/v1/profile/banners/${ID}`,
  PRODUCT_TO_CATALOG: (BUSINESS_ID, CATALOG_ID) =>
    `/business/v1/businesses/${BUSINESS_ID}/catalog/${CATALOG_ID}/`,
  REMOVE_PRODUCTS_FROM_CATALOG: (CATALOG_ID) =>
    `business/v1/businesses/catalog/${CATALOG_ID}/remove_products/`,
  PRODUCT_REVIEWS: (ID) => `/product/v1/products/${ID}/reviews/`,
  ANSWER: (productId, reviewId) =>
    `/product/v1/products/${productId}/reviews/${reviewId}/response/`,
  CLAIM_TO_REVIEW: (productId, reviewId) =>
    `/product/v1/products/${productId}/reviews/${reviewId}/claim/`,
  STOCK_PRODUCTS: (businessId) =>
    `/business/v1/businesses/${businessId}/discounts/`,
  PRODUCT_STATS: (id) => `/business/v1/stats/products/${id}`,
  STATS_ALL: (bussinessId) => `/business/v1/stats/${bussinessId}`,
  PAYMENT: '/payment/v1/payment/',
  PAYMENT_HISTORY: '/payment/v1/profile/transaction_history/',
  TARIFFS: '/payment/v1/profile/payment/',
  PURCHASE_WITH_WALLET: '/payment/v1/payment_from_wallet/',
  PURCHASE_WITH_PAYBOX: '/payment/v1/payment/',
  DELIVERY: (businessId) => `/business/v1/profile/delivery/${businessId}/`,
  DELIVERY_CREATE: '/business/v1/profile/delivery/',
};
