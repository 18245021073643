// ModalAddInCatalog.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useAlert } from 'react-alert';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, profileSelectors } from 'states/Profile';
import CatalogAdd from 'components/BusinessProfile/components/BusinessCatalog/components/CatalogAdd';
import CatalogCard from 'components/BusinessProfile/components/BusinessCatalog/components/CatalogCard';
import { BusinessActions } from 'states/Business';
import AddCatalogDialog from 'components/BusinessProfile/components/AddCatalogDialog';

const ModalAddInCatalog = ({ intl, open, handleClose, product }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const businessProfile = useSelector(profileSelectors.businessProfile);
  const [business, setBusiness] = useState();
  const [isAddCatalog, setAddCatalog] = useState(false);
  const [loading, setLoading] = useState(false);

  const addCatalog = () => {
    setAddCatalog((prevState) => !prevState);
    handleClose();
  };

  const updateCatalog = async () => {
    setLoading(true);
    try {
      const res = await dispatch(profileActions.getBusinessProfile());
      if (res) {
        setBusiness(res);
      }
    } finally {
      setLoading(false);
    }
  };

  const saveNewCatalog = async (catalog) => {
    try {
      const res = await dispatch(
        BusinessActions.sendCatalog(business.id, catalog),
      );
      setBusiness({ ...business, catalogs: [...business.catalogs, res] });
      setAddCatalog(false);
      updateCatalog();
    } catch (error) {
      console.error('Error saving catalog:', error);
    }
  };

  useEffect(() => {
    setBusiness(businessProfile);
  }, [businessProfile]);

  const defaultCatalogs = business?.catalogs.filter(
    (catalog) => !catalog.is_discount,
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      className={classes.root}
    >
      <div className={classes.dialogHeader}>
        <DialogTitle>Выберите каталог</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </div>
      <div className={classes.list}>
        <DialogContent>
          <CatalogAdd
            intl={intl}
            onClick={addCatalog}
          />
          {defaultCatalogs?.reverse().map((catalog) => (
            <CatalogCard
              intl={intl}
              isProductModal={true}
              updateCatalog={updateCatalog}
              catalog={catalog}
              loading={loading}
              key={catalog.id}
              product={product}
            />
          ))}
        </DialogContent>
      </div>
      <AddCatalogDialog
        intl={intl}
        open={isAddCatalog}
        close={addCatalog}
        saveChanges={saveNewCatalog}
      />
    </Dialog>
  );
};

export default ModalAddInCatalog;
