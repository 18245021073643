import { defineMessages } from 'react-intl';

export const scope = 'BusinessProfile';

export default defineMessages({
  contactsTitle: {
    id: `${scope}.contactsTitle`,
    defaultMessage: 'Контакты',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Сохранить',
  },
  workingDay: {
    id: `${scope}.workingDay`,
    defaultMessage: 'Рабочий день',
  },
  with: {
    id: `${scope}.with`,
    defaultMessage: 'С',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'До',
  },
  dayOff: {
    id: `${scope}.dayOff`,
    defaultMessage: 'Выходной',
  },
  addContact: {
    id: `${scope}.addContact`,
    defaultMessage: 'Добавить',
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: 'Сохранить',
  },
  cancelChanges: {
    id: `${scope}.cancelChanges`,
    defaultMessage: 'Отменить',
  },
  changeContactsTitle: {
    id: `${scope}.changeContactsTitle`,
    defaultMessage: 'Изменить Контакты',
  },
  changeDeliveryTitle: {
    id: `${scope}.changeDeliveryTitle`,
    defaultMessage: 'Изменить Сумму Доставки',
  },
  aboutBusiness: {
    id: `${scope}.aboutBusiness`,
    defaultMessage: 'О нас',
  },
  businessReviews: {
    id: `${scope}.businessReviews`,
    defaultMessage: 'отзыв(ов)',
  },
  scheduleBusiness: {
    id: `${scope}.scheduleBusiness`,
    defaultMessage: 'График работы',
  },
  notIndicated: {
    id: `${scope}.notIndicated`,
    defaultMessage: 'Не указано',
  },
  scheduleBusinessTitle: {
    id: `${scope}.scheduleBusinessTitle`,
    defaultMessage: 'Укажите время работы на ',
  },
  ratingStar: {
    id: `${scope}.ratingStar`,
    defaultMessage: 'Добавьте оценку к вашему отзыву',
  },
  deliveryBusiness: {
    id: `${scope}.deliveryBusiness`,
    defaultMessage: 'Доставка',
  },
  more: {
    id: `${scope}.more`,
    defaultMessage: 'Показать полностью',
  },
  hide: {
    id: `${scope}.hide`,
    defaultMessage: 'Скрыть',
  },
  monday: {
    id: `${scope}.monday`,
    defaultMessage: 'Понедельник',
  },
  tuesday: {
    id: `${scope}.tuesday`,
    defaultMessage: 'Вторник',
  },
  wednesday: {
    id: `${scope}.wednesday`,
    defaultMessage: 'Среда',
  },
  thursday: {
    id: `${scope}.thursday`,
    defaultMessage: 'Четверг',
  },
  friday: {
    id: `${scope}.friday`,
    defaultMessage: 'Пятница',
  },
  saturday: {
    id: `${scope}.saturday`,
    defaultMessage: 'Суббота',
  },
  sunday: {
    id: `${scope}.sunday`,
    defaultMessage: 'Воскресенье',
  },
  changeScheduleTitle: {
    id: `${scope}.changeScheduleTitle`,
    defaultMessage: 'Изменить График работы',
  },
  successSchedule: {
    id: `${scope}.successSchedule`,
    defaultMessage: 'График работы изменён.',
  },
  errorSchedule: {
    id: `${scope}.errorSchedule`,
    defaultMessage: 'Упс! Что-то пошло не так.',
  },
  deliveryTo: {
    id: `${scope}.deliveryTo`,
    defaultMessage: 'Доставка от',
  },
  deliveryCity: {
    id: `${scope}.deliveryCity`,
    defaultMessage: 'По городу',
  },
  catalogsTitle: {
    id: `${scope}.catalogsTitle`,
    defaultMessage: 'Объявления по категориям',
  },
  productsTitle: {
    id: `${scope}.productsTitle`,
    defaultMessage: 'Все объявления',
  },
  dateFilter: {
    id: `${scope}.dateFilter`,
    defaultMessage: 'По дате добавления',
  },
  categoryFilter: {
    id: `${scope}.categoryFilter`,
    defaultMessage: 'По категории',
  },
  priceFilter: {
    id: `${scope}.priceFilter`,
    defaultMessage: 'По возрастанию цены',
  },
  addCatalogTitle: {
    id: `${scope}.addCatalogTitle`,
    defaultMessage: 'Добавить новую группу товаров:',
  },
  addNewProductTitle: {
    id: `${scope}.addNewProductTitle`,
    defaultMessage: 'Добавить новое объявление',
  },
  addOldProductTitle: {
    id: `${scope}.addOldProductTitle`,
    defaultMessage: 'Добавить из существующих',
  },
  answer: {
    id: `${scope}.answer`,
    defaultMessage: 'Ответить',
  },
  claim: {
    id: `${scope}.claim`,
    defaultMessage: 'Пожаловаться',
  },
  claimToReview: {
    id: `${scope}.claimToReview`,
    defaultMessage: 'Пожаловаться на отзыв:',
  },
  claimTitle: {
    id: `${scope}.claimTitle`,
    defaultMessage:
      'Ваша жалоба будет передана на модерацию, после ее рассмотрения будут приняты меры',
  },
  createStock: {
    id: `${scope}.createStock`,
    defaultMessage: 'Создать акцию',
  },
  createStockTitle: {
    id: `${scope}.createStockTitle`,
    defaultMessage:
      'Размещайте скидки и распродажи ваших товаров для привлечения покупателей и сбыта залежавшихся товаров ',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Создать',
  },
  stockProducts: {
    id: `${scope}.stockProducts`,
    defaultMessage: 'Товары со скидкой',
  },
  stockProductsTitle: {
    id: `${scope}.stockProductsTitle`,
    defaultMessage: 'Акционные товары по лучшим ценам',
  },
  viewStockProducts: {
    id: `${scope}.viewStockProducts`,
    defaultMessage: 'Смотреть все',
  },
  stocks: {
    id: `${scope}.stocks`,
    defaultMessage: '% скидки',
  },
  statisticsTitle: {
    id: `${scope}.statisticTitle`,
    defaultMessage: 'Статистика объявления',
  },
  allStatisticsTitle: {
    id: `${scope}.allStatisticsTitle`,
    defaultMessage: 'Общая Статистика объявлений',
  },
  statisticViews: {
    id: `${scope}.statisticViews`,
    defaultMessage: 'Показы',
  },
  statisticImpressions: {
    id: `${scope}.statisticImpressions`,
    defaultMessage: 'Показы',
  },
  statisticsMessages: {
    id: `${scope}.statisticMessages`,
    defaultMessage: 'Сообщения',
  },
  statisticsCalls: {
    id: `${scope}.statisticCalls`,
    defaultMessage: 'Звонки',
  },
  noneActiveProducts: {
    id: `${scope}.noneActiveProducts`,
    defaultMessage: 'У вас нет активных объявлений',
  },
  balanceTitle: {
    id: `${scope}.balanceTitle`,
    defaultMessage: 'Кошелек',
  },
  walletTitle: {
    id: `${scope}.walletTitle`,
    defaultMessage: 'Баланс Кошелька',
  },
  balanceHistoryTitle: {
    id: `${scope}.balanceHistoryTitle`,
    defaultMessage: 'История платежей',
  },
  noReviewsMainTitle: {
    id: `${scope}.noReviewsMainTitle`,
    defaultMessage:
      'Здесь будут отображаться отзывы от пользователей на ваш продукт',
  },
  galleryBigTitle: {
    id: `${scope}.galleryBigTitle`,
    defaultMessage:
      'Загрузите корпоративные фотографии вашего магазина либо о вашей компании для узнаваемости вашего бренда',
  },
  balanceUp: {
    id: `${scope}.balanceUp`,
    defaultMessage: 'Поплонить',
  },
  balanceUpBigTitle: {
    id: `${scope}.balanceUpBigTitle`,
    defaultMessage:
      'Пополняя баланс Тez Sat Вы можете осуществлять платежи за услуги и хранить их в Вашем персональном кошельке',
  },
  balanceUpSelect: {
    id: `${scope}.balanceUpSelect`,
    defaultMessage: 'Выберит сумму пополнения',
  },
  anotherSumTitle: {
    id: `${scope}.anotherSumTitle`,
    defaultMessage: 'Введите сумму',
  },
  balanceUpAmount: {
    id: `${scope}.balanceUpAmount`,
    defaultMessage: 'Сумма пополнения',
  },
  selectPurchase: {
    id: `${scope}.selectPurchase`,
    defaultMessage: 'Выберите способ оплаты',
  },
  paymentHistory: {
    id: `${scope}.paymentHistory`,
    defaultMessage: 'История платежей',
  },
  paymentUp: {
    id: `${scope}.paymentUp`,
    defaultMessage: 'Пополнение аккунта',
  },
  vipPackTitle: {
    id: `${scope}.vipPackTitle`,
    defaultMessage: 'Платный пакет: VIP',
  },
  leftTimeTitle: {
    id: `${scope}.leftTimeTitle`,
    defaultMessage: 'Осталось: ',
  },
  demoBusinessTitle: {
    id: `${scope}.demoBusinessTitle`,
    defaultMessage:
      'На данный момент вы пользуетесь демо-версией Бизнес-профиля.',
  },
  purchase: {
    id: `${scope}.purchase`,
    default: 'Оплатить',
  },
  purchaseLater: {
    id: `${scope}.purchaseLater`,
    defaultMessage: 'Оплатить позже',
  },
  demoVersionExpired: {
    id: `${scope}.demoVersionExpired`,
    defaultMessage: 'Срок демо-версии истек',
  },
  demoVersionExpiredBigTitle: {
    id: `${scope}.demoVersionExpiredBigTitle`,
    defaultMessage:
      'Истек срок, выделенный для демо-версии. Чтобы пользоваться всеми преимуществами Бизнес-профиля нужно оплатить тариф.',
  },
  purchaseForBusiness: {
    id: `${scope}.purchaseForBusiness`,
    defaultMessage: 'Оплатиь за бизнес аккаунт',
  },
  selectOtherPeriod: {
    id: `${scope}.selectOtherPeriod`,
    defaultMessage: 'Выбрать другой период использования',
  },
  monthSum: {
    id: `${scope}.monthSum`,
    defaultMessage: 'Количество месяцев',
  },
  insufficientTitle: {
    id: `${scope}.insufficientTitle`,
    defaultMessage: 'Недостаточно средств',
  },
  insufficientTitleBig: {
    id: `${scope}.insufficientTitleBig`,
    defaultMessage:
      'Для совершения этого дейстия вам необходимо пополнить ваш элетронный кошелек, либо оплатить через PayBox',
  },
  goPurchase: {
    id: `${scope}.goPurchase`,
    defaultMessage: 'Перейти к оплате',
  },
  changeCatalogTitle: {
    id: `${scope}.changeCatalogTitle`,
    defaultMessage: 'Изменить название каталога',
  },
  doYouWantDeleteCatalog: {
    id: `${scope}.doYouWantDeleteCatalog`,
    defaultMessage: 'Вы хотите удалить данный каталог товаров?',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Удалить',
  },
  connectToBusiness: {
    id: `${scope}.connectToBusiness`,
    defaultMessage: 'Подключите Бизнес-профиль и увеличивайте продажи!',
  },
  businessMessage: {
    id: `${scope}.businessMessage`,
    defaultMessage: 'Tez Sat Business БЕСПЛАТНО?',
  },
  businessExtraMessage: {
    id: `${scope}.businessExtraMessage`,
    defaultMessage: 'Продавать быстрее и автоматизировать поднятия',
  },
  businessNavBtn: {
    id: `${scope}.businessNavBtn`,
    defaultMessage: 'Подробнее',
  },
  businessBtn: {
    id: `${scope}.businessBtn`,
    defaultMessage: 'Подключить',
  },
  productRequest: {
    id: `${scope}.productRequest`,
    defaultMessage: 'Ваша заявка на рассмотрении',
  },
  productRequestSubtitle: {
    id: `${scope}.productRequestSubtitle`,
    defaultMessage:
      ' После ее рассмотрения Вам откроется окно с дальнейшими инструкциями.',
  },
  productRequestSuccess: {
    id: `${scope}.productRequestSuccess`,
    defaultMessage: 'Ваша заявка принята!',
  },
  productRequestExtra: {
    id: `${scope}.productRequestExtra`,
    defaultMessage: 'Перейдите по ссылке для заполнения профиля',
  },
  productRequestBtn: {
    id: `${scope}.productRequestBtn`,
    defaultMessage: 'Перейти',
  },
  ExtraInfo: {
    id: `${scope}.ExtraInfo`,
    defaultMessage: 'адрес',
  },
  photoDelete: {
    id: `${scope}.photoDelete`,
    defaultMessage: 'Удалить фотографию?',
  },
  changeCatalog: {
    id: `${scope}.changeCatalog`,
    defaultMessage: 'Переименовать каталог',
  },
  deleteCatalog: {
    id: `${scope}.deleteCatalog`,
    defaultMessage: 'Удалить каталог',
  },
  profileRating: {
    id: `${scope}.profileRating`,
    defaultMessage: 'Средний рейтинг профиля',
  },
  myAdds: {
    id: `${scope}.myAdds`,
    defaultMessage: 'Объявления',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Настройки',
  },
  countStatisticViews: {
    id: `${scope}.countStatisticViews`,
    defaultMessage: 'Всего просмотров',
  },
  countStatisticImpressions: {
    id: `${scope}.countStatisticImpressions`,
    defaultMessage: 'Всего показов',
  },
  countStatisticsMessages: {
    id: `${scope}.countStatisticImpressions`,
    defaultMessage: 'Всего сообещний',
  },
  countStatisticsCalls: {
    id: `${scope}.countStatisticImpressions`,
    defaultMessage: 'Всего звонков',
  },
});
