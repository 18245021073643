import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { injectIntl, intlShape } from 'react-intl';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 80,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    borderRadius: 5,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      borderBottom: '0.6px solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  secondaryText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  title: {
    display: 'inline-block',
    maxWidth: 222,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondary: {
    display: 'inline-block',
    maxWidth: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  active: {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      display: 'block',
      width: 10,
      background: theme.palette.primary.main,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
  },
}));

function ListItem(props) {
  const classes = useStyles();
  const { avatar, title, lastMsg, date, photo, active, intl, ...rest } = props;
  return (
    <MuiListItem
      button
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
      {...rest}
    >
      <ListItemAvatar>
        <Avatar
          className={classes.large}
          alt="Avatar n°"
          src={avatar}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            component="span"
            variant="h6"
            className={classes.title}
          >
            {title}
          </Typography>
        }
        secondary={
          <span className={classes.secondaryText}>
            <Typography
              component="span"
              color="textSecondary"
              className={classes.secondary}
            >
              {lastMsg}
            </Typography>
            <Typography
              component="span"
              color="textSecondary"
              style={{ textAlign: 'center' }}
            >
              {date}
            </Typography>
          </span>
        }
      />
      <ListItemSecondaryAction>
        <Avatar
          className={classes.large}
          variant="rounded"
          alt="Avatar"
          src={photo}
        />
      </ListItemSecondaryAction>
    </MuiListItem>
  );
}

ListItem.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  title: PropTypes.string,
  lastMsg: PropTypes.string,
  date: PropTypes.string,
  photo: PropTypes.string,
  active: PropTypes.bool,
  intl: intlShape,
};

export default injectIntl(ListItem);
