import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: 384,
    },
    '& .MuiDialog-paper': {
      borderRadius: '5px',
      paddingBottom: '8px',
    },
    '& .MuiDialogTitle-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& h2': {
        maxWidth: 220,
        fontSize: 16,
        color: '#1D1D1D',
        textAlign: 'center',
      },
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
    '& .MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: 0,
    },
    '& .MuiList-root': {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
    },
  },
  link: {
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#0051BA',
      fontSize: '16px',
      fontWeight: 400,
    },
  },
  close: {
    maxWidth: 306,
    width: '100%',
    padding: '10px 32px !important',
    background: '#0051BA',
    '& .MuiButton-label': {
      color: '#FFF',
      fontSize: '16px',
      fontWeight: 400,
    },
  },
}));
