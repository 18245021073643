import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    minWidth: 139,
    height: 311,
    display: 'flex',
    borderRadius: '5px',
    background: theme.palette.primary.light,
    overflow: 'visible',
    boxShadow: '0px 4px 15px 0px rgba(36, 86, 151, 0.10)',
    '& .MuiCardActionArea-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    '@media (max-width: 560px)': {
      width: '100%',
      minWidth: 139,
      height: 199,
    },
  },
  skeletonRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  skeletonImage: {
    width: '100%',
    height: '100%',
  },
  skeletonContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 8,
  },
  skeletonText: {
    width: '60%',
    height: 20,
    marginBottom: 6,
  },
}));

const CatalogSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.skeletonRoot}>
        <Skeleton
          className={classes.skeletonImage}
          variant="rect"
        />
        <CardContent className={classes.skeletonContent}>
          <Skeleton
            className={classes.skeletonText}
            variant="text"
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CatalogSkeleton;
