import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '960px',
    },
    '& .MuiDialogContent-root': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: 16,
      '@media (max-width: 962px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      '@media (max-width: 680px)': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      '@media (max-width: 420px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
  },
  list: {
    height: 570,
    overflow: 'auto',
    marginBottom: 24,
  },
  dialogHeader: {
    position: 'relative',
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
    },
    '& .MuiIconButton-root': {
      position: 'absolute',
      top: 8,
      right: 8,
    },
  },
}));
