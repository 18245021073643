import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 139,
    height: 311,
    display: 'flex',
    borderRadius: '5px',
    background: theme.palette.primary.light,
    boxShadow: '0px 4px 15px 0px rgba(36, 86, 151, 0.10)',
    '& .MuiButtonBase-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    '@media (max-width: 560px)': {
      width: '100%',
      minWidth: 139,
      height: 199,
    },
  },
  images: {
    padding: 0,
    width: '100%',
    height: 255,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#D5E1F1',
    '& .MuiCardMedia-media': {
      height: 50,
      width: 50,
      '@media (max-width: 560px)': {
        height: 24,
        width: 24,
      },
    },
    '@media (max-width: 560px)': {
      height: 'auto',
      minHeight: 132,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 8px',
    '& .MuiTypography-h5': {
      color: theme.palette.primary.dark,
      fontSize: 16,
      fontWeight: 400,
      '@media (max-width: 560px)': {
        fontSize: 13,
      },
    },
    '@media (max-width: 560px)': {
      alignItems: 'flex-start',
      padding: 8,
    },
  },
}));
