import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& h4': {
      fontSize: '16px',
      marginLeft: '15px',
    },
  },
  rootList: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: 384,
    },
    '& .MuiDialog-paper': {
      borderRadius: '5px',
    },
    '& .MuiDialogTitle-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& h2': {
        maxWidth: 220,
        fontSize: 16,
        color: '#1D1D1D',
        textAlign: 'center',
      },
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    '& .MuiList-root': {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& .MuiListItem-root': {
      minWidth: 'auto',
      padding: 0,
      height: 40,
      width: 40,
    },
  },
  button: {
    color: '#fff',
    border: '0',
    background: 'rgba(154, 166, 182, 1)',
    maxWidth: 280,
    width: '100%',
    borderRadius: 5,
    textAlign: 'center',
    marginTop: 10,
    paddingTop: 7,

    '&:hover': {
      border: '0',
      background: theme.palette.primary.main,
      color: '#000',
      '& svg': {
        fill: '#000',
      },
    },

    '& svg': {
      marginRight: 14,
      transition: 'all 0.2s',
    },
  },
  iconShare: {
    color: '#0051BA !important',
  },
}));
