import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 370,
      width: '100%',
    },
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
      color: '#1D1D1D',
      fontSize: '16px',
      fontWeight: 400,
    },
  },
  field: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 32px',
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      padding: '16px 12px',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    padding: '0 32px 16px 32px',
    '& > :first-child': {
      overflow: 'hidden',
      color: '#0051BA',
      border: '1px solid #0051BA',
      textOverflow: 'ellipsis',
      fontSize: '16px',
      fontWeight: 400,
    },
    '& > :last-child': {
      color: '#FFF',
      background: '#0051BA',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 400,
    },
    '& .MuiButtonBase-root': {
      width: '100%',
      padding: '10px 32px',
      boxShadow: 'none',
    },
  },
  disabled: {
    background: '#DBDBDB !important',
    color: '#C2C2C2 !important',
  },
}));
