import instagram from 'assets/images/BusinessIcon/social/instagram.svg';
import telegram from 'assets/images/BusinessIcon/social/telegram.svg';
import whatsapp from 'assets/images/BusinessIcon/social/whatsapp.svg';
import mail from 'assets/images/BusinessIcon/social/mail.svg';
import phone from 'assets/images/BusinessIcon/social/phone.svg';
import facebook from 'assets/images/BusinessIcon/social/facebook.svg';
import website from 'assets/images/BusinessIcon/social/website.svg';
import address from 'assets/images/BusinessIcon/navigate/map-pin.svg';
import link from 'assets/images/BusinessIcon/navigate/unique-link.svg';

import whatsappColor from 'assets/images/BusinessIcon/social/colorWhatsApp.svg';
import vkColor from 'assets/images/BusinessIcon/social/colorVK.svg';
import okColor from 'assets/images/BusinessIcon/social/colorOK.svg';
import facebookColor from 'assets/images/BusinessIcon/social/colorFacebook.svg';
import telegramColor from 'assets/images/BusinessIcon/social/colorTelegram.svg';
import instagramColor from 'assets/images/BusinessIcon/social/colorInstagram.svg';

export const contactTypes = {
  telegram: telegram,
  instagram: instagram,
  facebook: facebook,
  email: mail,
  phone: phone,
  whatsapp: whatsapp,
  website: website,
  link: link,
};
export const profileContactsType = {
  whatsapp: whatsappColor,
  phone: phone,
  website: website,
  email: mail,
  address: address,
};

export const colorSocial = {
  vk: vkColor,
  ok: okColor,
  whatsapp: whatsappColor,
  facebook: facebookColor,
  telegram: telegramColor,
  instagram: instagramColor,
};
