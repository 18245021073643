import React, { useState } from 'react';
import {
  FacebookShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
  OKShareButton,
} from 'react-share';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
} from '@material-ui/core';
import { colorSocial } from 'pages/BusinessRegistration/Components/BusinessEditInfo/Utilities/ContactTypes';
import { useStyles } from './styles';
const APP_URL = 'https://tezsat.kg';

const componentsArray = [
  {
    smButton: TelegramShareButton,
    icon: colorSocial.telegram,
    title: 'TezSat',
  },
  {
    smButton: WhatsappShareButton,
    icon: colorSocial.whatsapp,
    title: 'TezSat',
  },
  {
    smButton: FacebookShareButton,
    icon: colorSocial.facebook,
    title: 'TezSat',
  },
  {
    smButton: VKShareButton,
    icon: colorSocial.vk,
    title: 'TezSat',
  },
  {
    smButton: OKShareButton,
    icon: colorSocial.ok,
    title: 'TezSat',
  },
];

const TYPE_LINK = 'default';

export const ListToShare = ({
  handleClose,
  id,
  productDescription,
  image,
  business,
  className,
  type = TYPE_LINK,
}) => {
  const classes = useStyles();
  const [link] = useState(
    type === TYPE_LINK
      ? business
        ? `${APP_URL}/user/${id}`
        : `${APP_URL}/link/share?product_id=${id}`
      : `${APP_URL}/products/${id}`,
  );

  return (
    <List className={`${classes.rootList} ${className}`}>
      {componentsArray.map((item, index) => (
        <ListItem
          key={`${index}-${item.title}`}
          onClick={handleClose}
        >
          <item.smButton
            url={link}
            title={productDescription}
          >
            <img
              src={item.icon}
              alt={item.title}
              width={40}
              height={40}
            />
          </item.smButton>
        </ListItem>
      ))}
    </List>
  );
};
