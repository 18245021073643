import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(23),

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();

  const [scroller, setScroller] = useState(null);

  useEffect(() => {
    const target = document.getElementById('scroller');

    if (target) {
      setScroller(target);
    }
  }, []);

  const trigger = useScrollTrigger({
    target: scroller || undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.root}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};
